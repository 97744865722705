import { useQuery } from "@tanstack/react-query";
import { getEventList, getOneEvent } from "api/eventApi";
import dayjs from "dayjs";
import { useMemo } from "react";
import { ResBaseVo } from "types/api";
import { ModooContentTemplate } from "types/event";
import { useContentDataQuery } from "./shopQuery";

export const useEventListQuery = () => {
  const { data } = useQuery<ResBaseVo<{ eventList: ModooContentTemplate[] }>>(
    ["EVENT"],
    getEventList,
  );

  const eventData: ModooContentTemplate[] = useMemo(() => {
    if (!data?.data.eventList) return [];
    return [
      // 0번 인덱스는 메인배너 책광장모두에 관하여를 담당하고 있다.
      {
        eventSeq: "EVENT00000000",
        title: "",
        content: "",
        bookCd: "",
        contentImg: [],
        contentText: [],
        eventTypCd: "X",
        eventTypNm: "기획전",
        bannerImg: "/imgs/mall/pc/EVENT00000000/BANNER_2880x630.png",
        recommendedBookList: [],
        url: "/about-modoo",
        startDate: dayjs().format("YYYYMMDD"),
        endDate: dayjs().format("YYYYMMDD"),
        elements: [
          {
            PC_BANNER_IMG_1: "/imgs/mall/pc/EVENT00000000/BANNER_2880x630.png",
            MO_BANNER_IMG_1: "/imgs/mall/mo/EVENT00000000/BANNER_1500x1880.jpg",
          },
        ],
      },
      ...data.data.eventList.map((event) => {
        const elements = event.elements[0];
        const bookCdArr: string[] = [];
        for (const key in elements) {
          if (key.includes("BOOK_SEQ")) {
            bookCdArr.push(elements[key]);
          }
        }
        const bookCd = bookCdArr.join(";");
        return {
          ...event,
          url: `/event/plan?eventSeq=${event.eventSeq}`,
          title: elements.TITLE,
          content: elements.CONTENT,
          bookCd,
          bannerImg: elements.BANNER_IMG,
          contentImg: [
            elements?.CONTENT_IMG_1 ?? "",
            elements?.CONTENT_IMG_2 ?? "",
            elements?.CONTENT_IMG_3 ?? "",
            elements?.CONTENT_IMG_4 ?? "",
          ],
          contentText: [
            elements?.CONTENT_TEXT_1 ?? "",
            elements?.CONTENT_TEXT_2 ?? "",
            elements?.CONTENT_TEXT_3 ?? "",
          ],
        };
      }),
    ];
  }, [data?.data.eventList]);

  const { elementList } = useContentDataQuery();

  const selectedEventData = useMemo(() => {
    if (eventData.length == 0) return [];
    if (elementList.length == 0) return [];

    const eventArr = [
      {
        // [1]메인배너 2
        modooCmptSeq: "CMPT0001",
        key: "EVENT_SEQ",
      },
      {
        // [2]메인배너 3
        modooCmptSeq: "CMPT0002",
        key: "EVENT_SEQ",
      },
      {
        // [3]메인배너 4
        modooCmptSeq: "CMPT0003",
        key: "EVENT_SEQ",
      },
      {
        // [4]메인배너 5
        modooCmptSeq: "CMPT0004",
        key: "EVENT_SEQ",
      },
      {
        // [5]두개짜리 오른쪽
        modooCmptSeq: "CMPT0006",
        key: "EVENT_SEQ",
      },
      {
        // [6]두개짜리 왼쪽
        modooCmptSeq: "CMPT0007",
        key: "EVENT_SEQ_1",
      },
      {
        // [7]1920x240
        modooCmptSeq: "CMPT0007",
        key: "EVENT_SEQ_2",
      },
      {
        // [8]세개짜리 1
        modooCmptSeq: "CMPT0007",
        key: "EVENT_SEQ_3",
      },
      {
        // [9]세개짜리 2
        modooCmptSeq: "CMPT0007",
        key: "EVENT_SEQ_4",
      },
      {
        // [10]세개짜리 3
        modooCmptSeq: "CMPT0007",
        key: "EVENT_SEQ_5",
      },
      {
        // [11]세개짜리 밑
        modooCmptSeq: "CMPT0007",
        key: "EVENT_SEQ_6",
      },
      {
        // [12] 기획/소식 페이지 상단배너
        modooCmptSeq: "CMPT0012",
        key: "EVENT_SEQ",
      },
    ]
      // MODOO_CMPT_SEQ과 EVENT_SEQ 혹은 EVENT_SEQ_N을 통해 eventSeq를 추출한다.
      .map(
        ({ modooCmptSeq, key }) =>
          elementList.find(
            (elem) => `${elem?.["MODOO_CMPT_SEQ"]}` == modooCmptSeq,
          )?.[key] ?? "",
      )
      // 추출된 eventSeq를 통해 실제 기획전/소식 객체와 맵핑시킨다.
      .map((eventSeq) => {
        const event: ModooContentTemplate = eventData.find(
          (evt) => evt.eventSeq == eventSeq,
        ) ?? {
          eventSeq,
          title: "",
          bookCd: "",
          eventTypCd: "ERROR",
          eventTypNm: "에러",
          bannerImg: "",
          content: "",
          contentImg: [],
          contentText: [],
          recommendedBookList: [],
          url: "",
          startDate: "",
          endDate: "",
          elements: [],
        };
        // 너는 무조건 있다. 없을 수가 없다.
        // 사실 없을 수도 있다. 조합 때문이다.
        return event;
      });

    return [eventData[0], ...eventArr];
  }, [eventData, elementList]);

  return { eventData, selectedEventData };
};

export const useEventDetailQuery = (eventSeq: string) => {
  const { data } = useQuery<ResBaseVo<{ eventOne: ModooContentTemplate }>>(
    ["EVENT", eventSeq],
    getOneEvent(eventSeq),
    {
      suspense: true,
    },
  );

  const eventDetailData: ModooContentTemplate = useMemo(() => {
    if (!data)
      return {
        eventSeq: "EVENT00000000",
        title: "",
        eventTypCd: "X",
        eventTypNm: "기획전",
        bannerImg: "",
        bookCd: "",
        content: "",
        contentImg: ["", "", "", ""],
        contentText: ["", "", ""],
        recommendedBookList: [],
        url: "",
        endDate: dayjs().format("YYYYMMDDHHmmss"),
        startDate: dayjs().format("YYYYMMDDHHmmss"),
        elements: [],
      };

    const elements = data.data.eventOne.elements[0];
    const bookCdArr: string[] = [];
    for (const key in elements) {
      if (key.includes("BOOK_SEQ")) {
        bookCdArr.push(elements[key]);
      }
    }
    const bookCd = bookCdArr.join(";");
    return {
      ...data.data.eventOne,
      title: elements.TITLE,
      content: elements.CONTENT,
      bookCd,
      bannerImg: elements.BANNER_IMG,
      contentImg: [
        elements?.CONTENT_IMG_1 ?? "",
        elements?.CONTENT_IMG_2 ?? "",
        elements?.CONTENT_IMG_3 ?? "",
        elements?.CONTENT_IMG_4 ?? "",
      ],
      contentText: [
        elements?.CONTENT_TEXT_1 ?? "",
        elements?.CONTENT_TEXT_2 ?? "",
        elements?.CONTENT_TEXT_3 ?? "",
      ],
    };
  }, [data]);
  return { eventDetailData };
};
